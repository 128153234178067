import { async } from "@firebase/util";
import { use } from "i18next";
import { useEffect, useState } from "react";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { notifyError, notifyMessage } from "../../helpers/utils";
import { storage, uploadFile } from '../../helpers/firebase_auth';
import { ref, getDownloadURL, uploadBytesResumable, list } from "firebase/storage";
import { useFetch, useSubmit } from "../../helpers/api_client";
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { quill, useQuill } from "react-quilljs";
import { toast } from "react-toastify";


export function CreateVocabDialog({ modelOpen, setModelOpen, updateVocabs, onReload }) {

    const [body, setBody] = useState({});
    const [image, setImage] = useState();
    const [loading, handleLoading] = useState(false)
    const quillRef = useQuill()
    const [file, setFile] = useState([]);
    const [words, setWord] = useState({ 0: {} });
    const [wordsCount, setWordCout] = useState(1)
    const [refersh, setRefresh] = useState(0)
    var uploadRef;


    useEffect(()=>{
        if(updateVocabs){
            var index = 0;
            const wordsToUpdate = {};
            updateVocabs.words.forEach(word=>{
                wordsToUpdate[index] = word;
                index++;
            })

            setWord(wordsToUpdate)
            setWordCout(index)
        }
    },[updateVocabs])


    const validateWords = () => {
        for (var _key of Object.keys(words)) {
            var word = words[_key]
            if (!word['word'] || !word['image']) {
                return false;
            }
        }
        return true;
    }

    const validateWordsOnly = () => {
        for (var _key of Object.keys(words)) {
            var word = words[_key]
            if (!word['word']) {
                return false;
            }
        }
        return true;
    }

    const uploadVocabs = async () => {


        if (!image) {
            return notifyError('Select image first')
        }

        if (!body['title']) {
            return notifyError('Enter valid title')
        }

        if (!body['coins']) {
            return notifyError('Select valid coins')
        }

        const validated = validateWords()

        if (!validated) {
            return notifyError('Select valid word')
        }


        handleLoading(true)

        var uploadList = [];
        const wordsBody = [];
        for (var _key of Object.keys(words)) {
            var word = words[_key]
            var _image = word['image']
            wordsBody.push({
                'word':word['word']
            })
            const imageUploadRef = uploadFile(`vocabs_images/` + _image.name, _image)
            uploadList.push(imageUploadRef)
        }

        const audioFileUploadRef = uploadFile(`vocabs_titles/` + image.name, image)
        uploadList.push(audioFileUploadRef)

        const urls = await Promise.all(uploadList)


        var titleImgUrl = urls.pop();

        for (var index in wordsBody) {
           const oldWord = wordsBody[index]
           oldWord['img'] = urls[index]
           wordsBody[index] = oldWord;
        }



        const reqBody = {
            ...body, "words": wordsBody,'img': titleImgUrl
        }

         console.log(reqBody);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('vocab/create', reqBody).then(res => {
            setImage(undefined)
            setBody(undefined)
            onReload()
            setFile(undefined)
            notifyMessage('Added')
            handleLoading(false)
            setWordCout(0)
            setWord({0:{}})
        }).catch(e => {
            handleLoading(false)
            notifyMessage(e.message)
        })
    }


    const update = async () => {


        const reqBody = {
            _id: updateVocabs['_id']
        }


       
        if (body['title']) {
            reqBody['tilte'] = body['title']
        }

        if (body['coins']) {
            reqBody['coins'] = body['coins']
        }

        const validated = validateWordsOnly()

        if (!validated) {
            return notifyError('Select valid word')
        }

        handleLoading(true)
        if (image) {
            const titleURL =await uploadFile(`vocabs_titles/` + image.name, image)
            reqBody['img'] = titleURL
        }



        const wordsBody = [];
        for (var _key of Object.keys(words)) {
            var word = words[_key]

            const newWord = {
                'word':word['word']
            }

            if(word['image']){
                var _image = word['image']
                var url = await uploadFile(`vocabs_images/` + _image.name, _image)
                newWord['img']= url;
            }else {
                newWord['img']= word['img'];
            }

            wordsBody.push(newWord)
          
        }

    
         reqBody['words'] = wordsBody

         console.log(reqBody);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('vocab/update', reqBody,'PUT').then(res => {
            setImage(undefined)
            setBody(undefined)
            onReload()
            setFile(undefined)
            notifyMessage('Added')
            handleLoading(false)
            setWordCout(0)
            setWord({0:{}})
        }).catch(e => {
            handleLoading(false)
            notifyMessage(e.message)
        })

    }



    const  reset = ()=>{
        setImage(undefined)
        setBody(undefined)
        setWordCout(1)
        setWord({0:{}})
        
    }

    return <>
        <input required={true} type="file" ref={(ref) => uploadRef = ref} onChange={(e, data) => {
            setImage(e.target.files[0])
        }} style={{ display: "none" }} />
        <Modal
        backdrop={'static'}
         isOpen={modelOpen} toggle={() => { setModelOpen(false); }}>
            <ModalHeader>
                Add Vocabs
                
            </ModalHeader>
            <ModalBody>
                {
                    <form>

                        <div>
                            <img className="img-thumbnail" alt="200x200" width="200" onClick={(e) => uploadRef.click()}
                                src={image !== undefined ? URL.createObjectURL(image) : updateVocabs ? updateVocabs['img'] : 'https://obsessedrnpod.com/wp-content/themes/soundbyte-progression/images/episode-placeholder.jpg'} />
                        </div>


                        <label htmlFor="message-text" className="col-form-label">Title</label>
                        <input className="form-control" placeholder="Title" id="message-text" rows="1" defaultValue={updateVocabs ? updateVocabs['title'] : ''} onChange={(e) => {
                            setBody({ ...body, title: e.target.value })
                        }}></input>

                        <label htmlFor="message-text" className="col-form-label">Coins</label>
                        <input className="form-control" placeholder="Coins" id="message-text" rows="1" type='number' defaultValue={updateVocabs ? updateVocabs['coins'] : ''} onChange={(e) => {
                            setBody({ ...body, coins: e.target.value })
                        }}></input>

                        <label htmlFor="message-text" className="col-form-label">Words</label>
                        {Array.from(Array(wordsCount).keys()).map((key) => {

                            console.log('key ' + key);
                            return wordRow(key,
                                words[key],
                                wordsCount - 1 == key,
                                (_key, _image) => {

                                    var oldWorld = words[_key]
                                    oldWorld['image'] = _image
                                    words[_key] = oldWorld;
                                    setWord(words)
                                    setRefresh(refersh + 1)
                                },
                                (_key, _word) => {
                                    var oldWorld = words[_key]
                                    oldWorld['word'] = _word
                                    words[_key] = oldWorld;
                                    setWord(words)
                                },
                                (_key) => {
                                    if (wordsCount > 0) {
                                        delete words[_key]
                                        setWord(words)
                                        setWordCout(wordsCount - 1)
                                    }
                                },
                                (_key) => {
                                    words[Object.keys(words).length] = {}
                                    setWord(words)
                                    setWordCout(wordsCount + 1)
                                },
                            )

                        })}
                    </form>
                }

            </ModalBody>
            <ModalFooter>
                <Button
                    color="light"
                    className="btn btn-light"
                    onClick={() => {
                        reset()
                        setModelOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary" onClick={() => { updateVocabs ? update() : uploadVocabs() }}>

                    {loading ? <Spinner size="sm" className="flex-shrink-0" role="status"> Loading... </Spinner> : updateVocabs ? "UPDATE" : 'ADD'}

                </Button>
            </ModalFooter>
        </Modal>
    </>
}




function wordRow(key, wordObject, isLast, onImageChange, onWordChange, deleteItem, onAdd) {
    var uploadRef;
    return <>
        <input required={true} type="file" ref={(ref) => uploadRef = ref} onChange={(e, data) => {
            onImageChange(key, e.target.files[0])
        }} style={{ display: "none" }} />
        <Row className="align-items-center">
            <Col sm={2} >
                <img className="img-thumbnail" alt="40x40" style={{ height: "50px", width: '50px', marginTop: "5px", marginBottom: "5px" }} onClick={(e) => uploadRef.click()}
                    src={wordObject !=undefined  && wordObject['image'] !== undefined ? URL.createObjectURL(wordObject['image']) : wordObject !=undefined  && wordObject['img'] !== undefined ?  wordObject['img'] :   'https://obsessedrnpod.com/wp-content/themes/soundbyte-progression/images/episode-placeholder.jpg'} />
            </Col>
            <Col >
                <input className="form-control" placeholder="Title" id="message-text" rows="1" defaultValue={ wordObject && wordObject['word'] ? wordObject['word'] : ''} onChange={(e) => {
                    onWordChange(key, e.target.value)
                }}></input>
            </Col>
            <Col sm={4}>
                <div>
                    {isLast && (<Button color="primary" className="btn-icon" style={{ marginRight: '10px' }} onClick={() => {
                        onAdd()
                    }}> <i className="ri-add-line" /> </Button>)}
                    {isLast && (<Button color="danger" className="btn-icon"> <i className="ri-delete-bin-5-line" onClick={() => { deleteItem(key) }} /> </Button>)}

                </div>
            </Col>
        </Row>
    </>
}

