



import { Grid, _ } from "gridjs-react"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, Toast } from "reactstrap"
import BreadCrumb from "../Components/Common/BreadCrumb"
import Loader from "../Components/Common/Loader"
import { userApis } from "../helpers/api"
import { useFetch } from "../helpers/api_client"
import { collection, endBefore, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../helpers/firebase_auth';
import DataTable from "react-data-table-component"
import { async } from "@firebase/util"
import moment from 'moment'

export const PromptsPage = () => {
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [itemsCount, setItemsCount] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [modelOpen, setModelOpen] = useState(false)

    const [deleteModelOpen, setDeleteModelOpen] = useState(false)
    const [deleteId, setDeleteId] = useState()


    const [title, setTitle] = useState('')

    const [lastRowItem, setLastRowItem] = useState();
    const [sortable, setSortable] = useState({
        name: 'title',
        type: 'asc'
    })

    const addPrompt = async () => {
        try {
            const docRef = await addDoc(collection(db, "topics"), {
                title: title,
                created_at: new Date().getTime()
            });
            setModelOpen(false)
            setTitle('')
        } catch (e) {

            console.error("Error adding document: ", e);
        }
    }

    const deletePrompt = async () => {
        try {
            const deleteRef = doc(db, 'topics', deleteId)
            await deleteDoc(deleteRef)
            setModelOpen(false)
            setTitle('')
        } catch (e) {

            console.error("Error adding document: ", e);
        }
    }


    var sn = 0;
    const column = [
        {
            name: "SN", selector: (row) => {

                return <span style={{ fontSize: '13px' }} >#{row['id']}</span>
            },

        },
        {
            name: "Propmt", sortable: true, selector: (row) => <React.Fragment>
                <div className="d-flex align-items-center fw-medium" >

                    <Link style={{ fontSize: '12px', marginLeft: '10px' }} to="#" className="currency_name">{row.title}</Link>
                </div>
            </React.Fragment>
        },
        { name: "Created At", sortable: true, selector: (row) => row.created_at ? moment(row.created_at).format('DD MMM hh:mm') : "" },

    ];


    useEffect(() => {
        fetchUsers();

    }, [page])


    useEffect(() => {
        fetchTotalItems();
    })


    const fetchUsers = async () => {
        try {

            var id = page == 0 ? 0 : page * 10;

            var q = query(collection(db, "topics"), orderBy(sortable['name'], sortable['type']), limit(10));
            if (lastRowItem && page > lastPage) {
                q = query(collection(db, "topics"), orderBy(sortable['name'], sortable['type']), startAfter(lastRowItem), limit(10));
            } else if (lastRowItem && lastPage > page) {
                q = query(collection(db, "topics"), orderBy(sortable['name'], sortable['type']), endBefore(lastRowItem), limit(10));
            }

            const querySnapshot = await getDocs(q);


            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastRowItem(lastVisible)
            const newData = querySnapshot.docs.map((doc) => {
                id++;
                return (
                    {
                        'title': doc.data().title,
                    
                    })
            });

            setData(newData)
            setLoading(false)

        } catch (e) {
            setLoading(false)
            console.error("aa", e)
        }

    }


    const fetchTotalItems = async () => {
        const coll = collection(db, "topics");
        const snapshot = await getCountFromServer(coll);
        setTotalItems(snapshot.data().count)
        console.log('count: ', snapshot.data().count);
    }


    if (loading) {
        return <Loader />
    }


    return (
        <>
            <Modal isOpen={modelOpen} toggle={() => { setModelOpen(true); }} id="exampleModal">
                <ModalHeader>
                    Add Prompt
                    <Button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModelOpen(false);
                        }}
                        aria-label="Close"
                    ></Button>
                </ModalHeader>
                <ModalBody>
                    <form>

                        <div className="mb-3">
                            <label htmlFor="message-text" className="col-form-label">Promot:</label>
                            <textarea className="form-control" id="message-text" rows="4" onChange={(e) => {
                                setTitle(e.target.value)
                            }}></textarea>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        className="btn btn-light"
                        onClick={() => {
                            setModelOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={addPrompt}>
                        Add prompt
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={deleteModelOpen}
                toggle={() => {
                    setDeleteModelOpen(false);
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
               
                <ModalBody className="text-center p-5">

                    <div className="mt-4">
                        <h4 className="mb-3">Delete ? </h4>
                        <p className="text-muted mb-4">Do you realy want to delete? </p>
                        <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setDeleteModelOpen(false)}><i className="ri-close-line me-1 align-middle"></i> Close</Link>
                            <Link to="#" className="btn btn-danger" onClick={()=>{}}>Delete </Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>

                                    <div>
                                        <Button color="success" onClick={() => {
                                            setModelOpen(true)
                                        }}> Add </Button>
                                    </div>
                                </CardHeader>

                                <CardBody className={"text-center"}>

                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map(row => {
                                                        sn++
                                                        return (<tr className="align-left" key={row['title']}>
                                                            <td>{"#" + sn}</td>
                                                            <td>{row['title']}</td>
                                                            <td><Button color="danger" size="sm" onClick={()=>{
                                                                setDeleteId(row['id'])
                                                                setDeleteModelOpen(true)
                                                            }}>Remove</Button></td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

