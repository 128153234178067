



import { Grid, _ } from "gridjs-react"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, Row, Spinner, Table, Toast, PaginationItem, PaginationLink } from "reactstrap"
import BreadCrumb from "../Components/Common/BreadCrumb"
import Loader from "../Components/Common/Loader"
import { userApis } from "../helpers/api"
import { useDelete, useFetch, useSubmit } from "../helpers/api_client"
import { collection, endBefore, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../helpers/firebase_auth';
import DataTable from "react-data-table-component"
import { async } from "@firebase/util"
import moment from 'moment'
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { quill, useQuill } from "react-quilljs";
import { CreatePodcastDialog } from "./dialogs/create_podcast"
import { notifyError, notifyMessage } from "../helpers/utils"
import { ref } from "firebase/storage"




export const PodcastsPage = () => {
    const [page, setPage] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [lastPage, setLastPage] = useState(1)
    const [itemsCount, setItemsCount] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [modelOpen, setModelOpen] = useState(false)
    const [updateDoc, setUpdateDoc] = useState()

    const [deleteModelOpen, setDeleteModelOpen] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [files, setFiles] = useState([]);

    const [title, setTitle] = useState('')
    const quillRef = useQuill()
    const [lastRowItem, setLastRowItem] = useState();
    const [sortable, setSortable] = useState({
        name: 'title',
        type: 'asc'
    })



    const enableDisable = async (id, enabledChecked)=>{
        console.log(id, enabledChecked)
         // eslint-disable-next-line react-hooks/rules-of-hooks
         useSubmit('podcast/enable', {_id:id, "enabled":enabledChecked},"PUT").then(res => {
           
            notifyMessage('Updated')
      
        }).catch(e => {
            notifyError(e.message)
        })
    }

    const deletePodcast = async () => {
        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const data = await useDelete('podcast/' + deleteId);
            setRefresh(!refresh)
            setDeleteId(undefined)
            setDeleteModelOpen(false)
            notifyMessage('Deleted successfully')
        } catch (e) {
            notifyError(e.messsage)
            console.error("Error adding document: ", e);
        }
    }


    var sn = 0;

    const { loading, error, data } = useFetch('podcast?page='+page, [refresh,page])


    if (loading) {
        return <Loader />
    }


    if (error) {
        return <h1>ERRROR</h1>
    }


    return (
        <>
            <CreatePodcastDialog
                updatePodcast={updateDoc}
                modelOpen={modelOpen} setModelOpen={() => {
                    setUpdateDoc(undefined)
                    setModelOpen(false)
                }}
                onReload={() => {
                    setUpdateDoc(undefined)
                    setModelOpen(false)
                    setRefresh(true)
                }}
            />



            <Modal
                isOpen={deleteModelOpen}
                toggle={() => {
                    setDeleteModelOpen(false);
                    setDeleteId(undefined)
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >

                <ModalBody className="text-center p-5">

                    <div className="mt-4">
                        <h4 className="mb-3">Delete ? </h4>
                        <p className="text-muted mb-4">Do you realy want to delete? </p>
                        <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setDeleteModelOpen(false)}><i className="ri-close-line me-1 align-middle"></i> Close</Link>
                            <Link to="#" className="btn btn-danger" onClick={() => {
                                deletePodcast()
                            }}>Delete </Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>

                                    <div>
                                        <Button color="success" onClick={() => {
                                            setModelOpen(true)
                                        }}> Add </Button>
                                    </div>
                                </CardHeader>

                                <CardBody className={"text-center"}>

                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Img</th>
                                                    <th>Title</th>
                                                    <th>Coins</th>
                                                    <th>Duration</th>
                                                    <th>Enabled</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.data.map(row => {
                                                        sn++
                                                        return (<tr className="align-left" key={row['_id']}>
                                                            <td>{"#" + sn}</td>
                                                            <td><img width={30} height={30} src={row['img']} alt="" className="avatar rounded" /></td>
                                                            <td>{row['title']}</td>
                                                            <td>{row['coins']}</td>
                                                            
                                                            <td>{row['duration']}</td>
                                                            <td> <Input className="form-check-input" type="checkbox" id="formCheck17" defaultChecked={row['enabled'] && row['enabled']} onChange={e=>(enableDisable(row['_id'],e.target.checked,))}/></td>
                                                            <td>
                                                                <Button color="success" size="sm" onClick={() => {
                                                                    setUpdateDoc(row)
                                                                    setModelOpen(true)
                                                                }}>Edit</Button>

                                                                <span style={{ marginLeft: "6px" }}></span>
                                                                <Button color="danger" size="sm" onClick={() => {
                                                                    setDeleteId(row['_id'])
                                                                    setDeleteModelOpen(true)
                                                                }}>Remove</Button></td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                            <tfoot>
                                                <Pagination aria-label="Page navigation example">
                                                    <PaginationItem>
                                                        <PaginationLink previous href="#" onClick={()=>{
                                                            if(page>0){
                                                                setPage(page - 1)
                                                            }
                                                             
                                                        }}  />
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#">
                                                            {page + 1} of {data['total_pages'] +1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    
                                                    <PaginationItem>
                                                        <PaginationLink next href="#" onClick={()=>{
                                                            if(data['total_pages'] > page){
                                                                setPage(page + 1)
                                                            }
                                                          
                                                        }} />
                                                    </PaginationItem>
                                                </Pagination>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

