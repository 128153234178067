import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions

import logoLight from "../../assets/images/zeptagram.svg";
//Import config
import { facebook, google } from "../../config";
import { useSubmit } from '../../helpers/api_client';
import { userApis } from '../../helpers/api';
import { notifyMessage } from '../../helpers/utils';
import ReCAPTCHA from 'react-google-recaptcha';
//import images
import app,{db} from '../../helpers/firebase_auth';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'

const Login = (props) => {
    const dispatch = useDispatch();


    const [userLogin, setUserLogin] = useState([]);
    const [showPassword, setShowPassword] = useState(false)

    const [error,setError] = useState();
    const [loading,setLoading] = useState(false);
    const [captcha, setCaptcha] = useState()


    const handleChange = (event) => {

       
        // this.setState({
        //     'g-recaptcha-response': event
        // });
        if (event.length > 0) {
            setCaptcha(event)
            // this.setState({
            //     captcha: true
            // });
        }
    };


    
    const signIn = async(user)=>{
        console.log(user)
         
         try{
            setError(undefined)
            setLoading(true)
            // eslint-disable-next-line react-hooks/rules-of-hooks
           
            const authentication = getAuth();
            const response = await signInWithEmailAndPassword(authentication,user.email, user.password )
            const idToken = response["_tokenResponse"]['idToken']
            const refreshToken = response["_tokenResponse"]['refreshToken']
            console.log(idToken,refreshToken)
          //  console.log('message', JSON.stringify(loginResponse))
            localStorage.setItem('token', idToken)
            localStorage.setItem('refresh_token', refreshToken)
            sessionStorage.setItem('authUser',JSON.stringify({
                "token":idToken,
                'refreshToken':refreshToken
            }))

            window.location.replace('/')
            setLoading(false)
         }catch(e){
            setLoading(false)
            setError(e.message)
            //topcenternotify()
            console.error("ERRRR",e)
         }
       
    }

    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            signIn(values)
            //dispatch(loginUser(values, props.history));
        }
    });

   




    document.title = "SpeakFluent Login";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="80" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Zeptagram</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to Zeptagram.</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        //value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={showPassword?'text' : 'password'}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button onClick={()=>{
                                                            setShowPassword(!showPassword)
                                                        }} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>


                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">
                                                        {
                                                            loading? ( <Spinner size="sm" className="flex-shrink-0"/>) :"Sign In"
                                                        }
                                                   
                                                        
                                                        </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);