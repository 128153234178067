

import { Grid, _ } from "gridjs-react"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table, Toast } from "reactstrap"
import BreadCrumb from "../Components/Common/BreadCrumb"
import Loader from "../Components/Common/Loader"
import { userApis } from "../helpers/api"
import { useFetch } from "../helpers/api_client"
import { collection, endBefore, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where ,updateDoc,doc} from "firebase/firestore";
import { db } from '../helpers/firebase_auth';
import DataTable from "react-data-table-component"
import { async } from "@firebase/util"
import moment from 'moment'

export const UsersPage = () => {
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [itemsCount, setItemsCount] = useState(10)
    const [totalItems, setTotalItems] = useState(0)

    const [lastRowItem, setLastRowItem] = useState();
    const [sortable, setSortable] = useState({
        name: 'created_at',
        type: 'desc'
    })


    const sn = 0;
    const column = [
        {
            name: "SN", selector: (row) => {

                return <span style={{ fontSize: '13px' }} >#{row['id']}</span>
            },

        },
        {
            name: "Name", sortable: true, selector: (row) => <React.Fragment>
                <div className="d-flex align-items-center fw-medium" >
                    <img width={30} height={30} src={row.img ? row.img : row['gender'] == 'male' ? 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=200' : "https://www.w3schools.com/w3images/avatar6.png"} alt="" className="avatar rounded" />
                    <Link style={{ fontSize: '12px', marginLeft: '10px' }} to="#" className="currency_name">{row.name}</Link>
                </div>
            </React.Fragment>
        },
        { name: "Phone", selector: (row) => <span style={{ fontSize: '14px' }} >{row.phone}</span> },
        { name: "Country", sortable: true, selector: (row) => <span style={{ fontSize: '14px' }} >{row.country_name}</span> },
        {
            name: "Gender", sortable: true, selector: (row) => {
                if (row.gender == 'male') {
                    return <span style={{ fontSize: '12px' }} className="badge badge-soft-info"> {row.gender} </span>;
                }
                else {
                    return <span style={{ fontSize: '12px' }} className="badge badge-soft-danger"> {row.gender} </span>;
                }
            }
        },
        { name: "Coins", sortable: true, selector: (row) => row.coins },
        { name: "created_at", sortable: true, selector: (row) => row.created_at ? moment(row.created_at).format('DD MMM hh:mm') : "" },

    ];


    useEffect(() => {
        fetchUsers();

    }, [page, sortable['name'], sortable['type']])


    useEffect(() => {
        fetchTotalItems();
        //updateCreatedAt();
    }, [])


    const updateCreatedAt = async () => {
        var q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.map(async (user) => {
            if (!user.data()['created_at']) {

                const washingtonRef = doc(db, "users", user.data()['uid']);

                // Set the "capital" field of the city 'DC'
                await updateDoc(washingtonRef, {
                    created_at: Date.parse('2022-10-10')
                });

               // await db.collection('users').doc(doc.data()['uid']).update({ "timestamp": new Date().getTime() })
                console.log('updated....')
                console.log("document ", user.data()['uid']);
                // console.log(doc.data()['uid'],'no timstamp found')
            }
           
        })

    }
    const fetchUsers = async () => {
        try {

            var q = query(collection(db, "users"), orderBy(sortable['name'], sortable['type']), limit(1));



            var id = page == 0 ? 0 : page * 10;

            var q = query(collection(db, "users"), orderBy(sortable['name'], sortable['type']), limit(10));

            if (lastRowItem && page > lastPage) {
                q = query(collection(db, "users"), orderBy(sortable['name'], sortable['type']), startAfter(lastRowItem), limit(10));
            } else if (lastRowItem && lastPage > page) {
                q = query(collection(db, "users"), orderBy(sortable['name'], sortable['type']), endBefore(lastRowItem), limit(10));
            }

            const querySnapshot = await getDocs(q);


            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastRowItem(lastVisible)
            const newData = querySnapshot.docs.map((doc) => {
                id++;
                return (
                    {
                        'name': doc.data().name,
                        "uid": doc.data().uid,
                        'gender': doc.data().gender,
                        'phone': doc.data().phone,
                        'country_name': doc.data().country_name,
                        'coins': doc.data().coins,
                        'img': doc.data().img,
                        'created_at': doc.data().created_at,
                        'id': id
                    })
            });

            setData(newData)
            setLoading(false)


        } catch (e) {
            setLoading(false)
            console.error("aa", e)
        }

    }


    const fetchTotalItems = async () => {
        const coll = collection(db, "users");
        const snapshot = await getCountFromServer(coll);
        setTotalItems(snapshot.data().count)
        console.log('count: ', snapshot.data().count);
    }


    if (loading) {
        return <Loader />
    }




    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Users</h4>
                                </CardHeader>

                                <CardBody className={"text-center"}>

                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div>

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <div>
                                                <div className="table-responsive">
                                                    <DataTable
                                                        data={data}
                                                        columns={column}
                                                        sort={true}
                                                        progressPending={loading}
                                                        paginationTotalRows={totalItems}
                                                        paginationServer={true}
                                                        onSort={(_data, sortType) => {
                                                            console.log(_data, sortType)
                                                            setSortable({
                                                                'name': _data['name'].toLowerCase(),
                                                                'type': sortType
                                                            })
                                                            // setSortable(_data['name'].toLowerCase())
                                                        }}
                                                        onChangeRowsPerPage={(page) => {
                                                            console.log("row page change" + page)
                                                        }}

                                                        onChangePage={(_page, totalRows) => {
                                                            setLastPage(page)
                                                            setPage(_page)

                                                            console.log(page)
                                                        }}
                                                        paginationDefaultPage={1}

                                                        pagination={true}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}








