import { async } from "@firebase/util"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, Toast } from "reactstrap"
import { useFetch, useSubmit } from "../helpers/api_client"
import { notifyError, notifyMessage } from "../helpers/utils"


export const PushNotification = () => {

    const [body, setBody] = useState({})
    const [loading, setLoading] = useState(false)
    const [modelOpen, setModelOpen] = useState(false)
    
    const sendNotification = async () => {

        try {
            setLoading(true)
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await useSubmit('notifications/send-notification',body)
            notifyMessage('Successfully pushed')
            setLoading(false)
            setModelOpen(false)
        } catch (e) {
            notifyError(e.message)
            setLoading(false)
        }
    }


    const saveNotification = async () => {

        try {
            
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await useSubmit('notifications/save-notification',{...body,'body':"test"})
            notifyMessage('Successfully saved')
        
        } catch (e) {
            notifyError(e.message)
           
        }
    }


    const {loading:notifationLoading,error,data} = useFetch('notifications/notifications-history')

    return <>

        <Modal
            isOpen={modelOpen}
            toggle={() => {
                setModelOpen(false);

            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >

            <ModalBody className="text-center p-5">

                <div className="mt-4">
                    <h4 className="mb-3">{body['title']}</h4>
                    <p className="text-muted mb-4">{body['message']}</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setModelOpen(false)}><i className="ri-close-line me-1 align-middle"></i> Close</Link>
                        <Link to="#" className="btn btn-danger" onClick={() => {
                            sendNotification()
                        }}>
                            {loading ? <Spinner size="sm" className="flex-shrink-0" role="status"> Loading... </Spinner> : 'Push now'}
                            </Link>
                    </div>
                </div>
            </ModalBody>
        </Modal>
       ( <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                Push Notification
                            </CardHeader>

                            <CardBody>
                                <input placeholder="Title" className="form-control sm-3" id="message-text" rows="1" onChange={(e) => {
                                    setBody({...body, title:e.target.value})
                                }}></input>
                                <div style={{ marginBottom: "20px" }}>

                                </div>


                                <textarea placeholder="Message" className="form-control" id="message-text" rows="4" onChange={(e) => {
                                     setBody({...body, message:e.target.value})
                                }}></textarea>
                                <div style={{ marginBottom: "10px" }}>

                                </div>
                                <Button onClick={()=>{
                                    if(body['title'] && body['message']){
                                        setModelOpen(true)
                                    }
                                }} className="btn-success">Send</Button>

                                
                                <Button onClick={()=>{
                                    if(body['title'] && body['message']){
                                        saveNotification()
                                    }
                                }} className="btn-success ms-2">Save</Button>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>


              {data &&  ( <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                Notification History
                            </CardHeader>

                            <CardBody>
                            <Table className="table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Title</th>
                                                    <th>Body</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map(row => {
                                                        
                                                        return (<tr className="align-left" key={row['_id']}>
                                                            <td>{"#" + 'sn'}</td>
                                                            <td>{row['title']}</td>
                                                            <td>{row['message']}</td>
                                                           
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>)}
                
            </Container>
        </div>)

    </>

}