// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import firebase from 'firebase/compat/app'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3k_GmtoPfFI3V93VOdu_Zy0nwMLQVyFo",
  authDomain: "speak-fluent-a7529.firebaseapp.com",
  projectId: "speak-fluent-a7529",
  storageBucket: "speak-fluent-a7529.appspot.com",
  messagingSenderId: "503004064116",
  appId: "1:503004064116:web:b8bab68a7a2ff2f6d6a421",
  measurementId: "G-KC77C73QVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export default app;

//export {db}




const uploadFile = async (filepath,_file) => {
       
  return new Promise((resolve,reject)=>{
      const storageRef = ref(storage,filepath);
      const uploadTask = uploadBytesResumable(storageRef, _file);

      uploadTask.on('state_changed',
          (snapShot) => {
              console.log(snapShot)
          }, (err) => {
              reject(err)
          }, () => {
              getDownloadURL(uploadTask.snapshot.ref).then((fireBaseUrl) => {
                resolve(fireBaseUrl)
              });

          })
  })

}




export {db,storage,uploadFile}