import { async } from "@firebase/util";
import { use } from "i18next";
import { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { notifyError, notifyMessage } from "../../helpers/utils";
import { storage, uploadFile } from '../../helpers/firebase_auth';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useFetch, useSubmit } from "../../helpers/api_client";
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { quill, useQuill } from "react-quilljs";
import { toast } from "react-toastify";


export function CreatePodcastDialog({ modelOpen, setModelOpen, updatePodcast, onReload }) {

    const [body, setBody] = useState({});
    const [image, setImage] = useState();
    const [loading, handleLoading] = useState(false)
    const quillRef = useQuill()
    const [file, setFile] = useState([]);

    var uploadRef;



    const uploadPodcast = async () => {


        if (!image) {
            return notifyError('Select image first')
        }


        if (!file) {
            return notifyError('Select podcast file first')
        }

        if (!body['title']) {
            return notifyError('Enter valid title')
        }

        if (!body['category_id']) {
            return notifyError('Select valid category')
        }

        if (!body['coins']) {
            return notifyError('Select valid coins')
        }

        if (!body['duration']) {
            return notifyError('Select valid duration')
        }

        handleLoading(true)

        const audioFileUploadRef = uploadFile(`podcast_files/` + file.name, file)
        const imageUploadRef = uploadFile(`podcast_images/` + image.name, image)

        const urls = await Promise.all([audioFileUploadRef, imageUploadRef])
        const podcastFile = urls[0]
        const podcastImage = urls[1]


        const reqBody = {
            ...body, podcast_file: podcastFile, img: podcastImage
        }

        console.log(reqBody);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('podcast/create', reqBody).then(res => {
            setImage(undefined)
            setBody(undefined)
            onReload()
            setFile(undefined)
            notifyMessage('Added')
            handleLoading(false)
        }).catch(e => {
            handleLoading(false)
            notifyMessage(e.message)
        })
    }


    const update = async () => {


        const reqBody = {
            _id: updatePodcast['_id']
        }

        Object.keys(body).forEach((key) => {
            if (body[key] && body[key] != '') {
                reqBody[key] = body[key]
            }
        })


        if (image) {
            reqBody['img'] = await uploadFile(image.name, image)
        }

        handleLoading(true)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('podcast/update', reqBody, 'PUT').then(res => {

            setImage(undefined)
            setBody(undefined)
            setFile(undefined)
            onReload()
            notifyMessage('Updated')
            handleLoading(false)
        }).catch(e => {
            handleLoading(false)
            notifyMessage(e.message)
        })
    }





    const { loading: categoryLoading, error, data } = useFetch('podcast_category', [])



    return <>
        <input required={true} type="file" ref={(ref) => uploadRef = ref} onChange={(e, data) => {
            setImage(e.target.files[0])
        }} style={{ display: "none" }} />
        <Modal isOpen={modelOpen} toggle={() => { setModelOpen(false); }}>
            <ModalHeader>
                Add Podcast
                <Button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setModelOpen(false);
                    }}
                    aria-label="Close"
                ></Button>
            </ModalHeader>
            <ModalBody>
                {
                    categoryLoading ? (<Spinner size="sm" className="flex-shrink-0" role="status"> Loading... </Spinner>) :
                        (
                            <form>

                                <div>
                                    <img className="img-thumbnail" alt="200x200" width="200" onClick={(e) => uploadRef.click()}
                                        src={image !== undefined ? URL.createObjectURL(image) : updatePodcast ? updatePodcast['img'] : 'https://obsessedrnpod.com/wp-content/themes/soundbyte-progression/images/episode-placeholder.jpg'} />
                                </div>


                                <label htmlFor="message-text" className="col-form-label">Title</label>
                                <input className="form-control" placeholder="Title" id="message-text" rows="1" defaultValue={updatePodcast ? updatePodcast['title'] : ''} onChange={(e) => {
                                    setBody({ ...body, title: e.target.value })
                                }}></input>

                                <label htmlFor="message-text" className="col-form-label">Coins</label>
                                <input className="form-control" placeholder="Coins" id="message-text" rows="1" type='number' defaultValue={updatePodcast ? updatePodcast['coins'] : ''} onChange={(e) => {
                                    setBody({ ...body, coins: e.target.value })
                                }}></input>

                                <label htmlFor="message-text" className="col-form-label">Duration(Minutes)</label>
                                <input className="form-control" placeholder="Duration(Minutes)" id="message-text" rows="1" type='number' defaultValue={updatePodcast ? updatePodcast['duration'] : ''} onChange={(e) => {
                                    setBody({ ...body, duration: e.target.value })
                                }}></input>


                                <label htmlFor="message-text" className="col-form-label">Category</label>

                                <select onChange={e => {
                                    setBody({ ...body, category_id: e.target.value })
                                }} className="form-select" defaultValue={updatePodcast ? updatePodcast['category_id'] : ''} aria-label="Default select example">
                                    <option >Select Category</option>
                                    {

                                        data.map(row => {
                                            console.log(row['_id']);
                                            return (<option key={row['_id']} value={row['_id']}>{row['category']}</option>)
                                        })

                                    }

                                </select>



                                {!updatePodcast && (<><label htmlFor="message-text" className="col-form-label">File</label>
                                    <Input className="form-control" id="formSizeDefault" onChange={(e) => {
                                        const file = e.target.files[0]
                                        setFile(file)
                                    }} type="file" /></>)}

                                <label htmlFor="message-text" className="col-form-label">Description</label>
                                <textarea className="form-control" placeholder="Description" id="message-text" rows="10"  defaultValue={updatePodcast ? updatePodcast['description'] : ''} onChange={(e) => {
                                    setBody({ ...body, description: e.target.value })
                                }}></textarea>


                                {/* 
                                <div style={{ marginTop: '16px' }}>
                                    <label>Podcast Audio</label>
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={false}
                                        maxFiles={1}
                                        name="files"
                                        className="filepond filepond-input-multiple"
                                    />
                                </div> */}
                            </form>
                        )
                }

            </ModalBody>
            <ModalFooter>
                <Button
                    color="light"
                    className="btn btn-light"
                    onClick={() => {
                        setModelOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary" onClick={() => { updatePodcast ? update() : uploadPodcast() }}>

                    {loading ? <Spinner size="sm" className="flex-shrink-0" role="status"> Loading... </Spinner> : updatePodcast ? "UPDATE" : 'ADD'}

                </Button>
            </ModalFooter>
        </Modal>
    </>
}




