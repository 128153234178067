import { async } from "@firebase/util";
import { use } from "i18next";
import { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { notifyMessage } from "../../helpers/utils";
import { storage } from '../../helpers/firebase_auth';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useSubmit } from "../../helpers/api_client";

export function AddCategoryPodcast({ modelOpen, setModelOpen, updateCategory,onReload }) {

    const [title, setTitle] = useState(updateCategory ? updateCategory['category'] : "");
    const [file, setFile] = useState();
    const [loading, handleLoading] = useState(false)
    var uploadRef;



    const addCategory = async (url) => {
        const body = {
            'img':url,
            'category':title,
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('podcast_category/create',body).then(res=>{
            setFile(undefined)
            setTitle(undefined)
            onReload()
            notifyMessage('Added')
            handleLoading(false)
        }).catch(e=>{
            handleLoading(false)
            notifyMessage(e.message)
        })
    }


    const update = (url) => {
        const body = {
            'img':url,
            'category':title,
            '_id':updateCategory['_id']
        }

        console.log(body)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubmit('podcast_category/update',body,'PUT').then(res=>{
            setFile(undefined)
            setTitle(undefined)
            onReload()
            notifyMessage('Added')
            handleLoading(false)
        }).catch(e=>{
            handleLoading(false)
            notifyMessage(e.message)
        })
    }



    const uploadImage = async () => {
        if (updateCategory && !file) {
            update()
            return;
        }


        if (!title && !updateCategory) return


        if (!file) {
            return notifyMessage("Please select image ", "error")
        }

        handleLoading(true)

        console.log('starting upload')

        const storageRef = ref(storage, `podcast_cagtegories/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it is happening
                console.log(snapShot)
            }, (err) => {
                handleLoading(false)
                notifyMessage("Upload image error: " + err.message, "error")
                //catches the errors
                console.log(err)
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then((fireBaseUrl) => {
                    if (updateCategory) {
                        update(fireBaseUrl)
                    } else addCategory(fireBaseUrl)
                });

            })

    }





    return <>
        <input required={true} type="file" ref={(ref) => uploadRef = ref} onChange={(e, data) => {
            setFile(e.target.files[0])
        }} style={{ display: "none" }} />
        <Modal  isOpen={modelOpen} toggle={() => { setModelOpen(false); }}>
            <ModalHeader>
                Add Category
                <Button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setModelOpen(false);
                    }}
                    aria-label="Close"
                ></Button>
            </ModalHeader>
            <ModalBody>
                <form>

                    <div>
                        <img className="img-thumbnail" alt="200x200" width="200" onClick={(e) => uploadRef.click()}
                            src={file !== undefined ? URL.createObjectURL(file) : updateCategory ? updateCategory['img'] : 'https://obsessedrnpod.com/wp-content/themes/soundbyte-progression/images/episode-placeholder.jpg'} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message-text" className="col-form-label">Category</label>
                        <Input 
                        placeholder="Category"
                        defaultValue={updateCategory? updateCategory['category'] : ''}
                        className="form-control" id="message-text" rows="1" onChange={(e) => {
                            setTitle(e.target.value)
                        }}></Input>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="light"
                    className="btn btn-light"
                    onClick={() => {
                        setModelOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary" onClick={() => { uploadImage() }}>

                    {loading ? <Spinner size="sm" className="flex-shrink-0" role="status"> Loading... </Spinner> : updateCategory ? "UPDATE" : 'ADD'}

                </Button>
            </ModalFooter>
        </Modal>
    </>
}


