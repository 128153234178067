import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";



const rootReducer = combineReducers({
    // public
    Layout,
    Login,

    ForgetPassword,
   
    DashboardEcommerce,
    
});

export default rootReducer;