import {useEffect, useReducer} from 'react';
import axios from 'axios';
import {apiReducer, initialState} from './reducer';


const localURL = 'http://localhost:4000/admin'

const prodUrl = 'https://app.speakfluent.link/admin'
const baseUrl = prodUrl;


export function useFetch(url, initialData = []) {
 // var authData = sessionStorage.getItem('authData');
 // var tokenData = JSON.parse(authData);
 // console.log(tokenData)
  const token = localStorage.getItem('token');
  // console.log('token is: ', token, url);
  const [data, dispatch] = useReducer(apiReducer, initialState);
  useEffect(() => {
    dispatch({type: 'DATA_FETCH_START'});
    fetch(baseUrl+"/"+url, {
      method:"GET",
      headers: new Headers({
        'x-auth-token': token,
        'Content-Type': 'application/json',
      }),
    })
      .then(async response => {
        if (!response.ok) {
          const error = await response.text();
          throw {
            status: response.status,
            error: parseError(error),
          };
        }
        return response.json();
      })
      .then(json => {
        dispatch({type: 'DATA_FETCH_SUCCESS', payload: json});
      })
      .catch(error => {
        dispatch({type: 'DATA_FETCH_FAILURE', payload: error});
      });
  }, initialData);
  return data;
}

export async function useSubmit(endpoint, body, type = 'POST') {
//  const token = authData.getToken();
  // console.log('token is: ', token);
  return fetch(baseUrl+"/"+endpoint, {
    method: type,
    headers: new Headers({
     // 'x-auth-token': token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  }).then(async response => {
    if (!response.ok) {
      const error = await response.text();
      const parsedErr = JSON.parse(error);
      if (parsedErr.device_id && parsedErr.user_id) {
        throw {
          status: response.status,
          message: parseError(error),
          device_id: parsedErr.device_id,
          user_id: parsedErr.user_id,
        };
      }
      if (parsedErr.email) {
        throw {
          status: response.status,
          message: parseError(error),
          email: parsedErr?.email,
        };
      }
      throw {
        status: response.status,
        message: parseError(error),
      };
    }

    const result = await response.json();

    if (result.success !== undefined && !result.success) {
      throw result;
    }
    return result;
  });
}

export function fetchUrl(url) {
  //const token = authData.getToken();

  return axios.get(url, {
    headers: {
      //'x-auth-token': token,
      'Content-Type': 'application/json',
    },
  });
}

export const useUpload = async (url, body) => {
 //const token = authData.getToken();
  return axios.patch(url, body, {
    headers: {
   //   'x-auth-token': token,
      'Content-Type': 'form-data',
    },
  });
};

export async function useDelete(url) {
 // const token = authData.getToken();
  return axios.delete(baseUrl+"/"+url, {
    headers: {
   //   'x-auth-token': token,
    },
  });
}

function parseError(str) {
  try {
    const json = JSON.parse(str);
    return json.message;
  } catch (e) {
    return str || 'Oops! Something went wrong';
  }
}
