



import { Grid, _ } from "gridjs-react"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, Toast } from "reactstrap"
import BreadCrumb from "../Components/Common/BreadCrumb"
import Loader from "../Components/Common/Loader"
import { userApis } from "../helpers/api"
import { useDelete, useFetch } from "../helpers/api_client"
import { collection, endBefore, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../helpers/firebase_auth';
import DataTable from "react-data-table-component"
import { async } from "@firebase/util"
import moment from 'moment'
import { AddCategoryPodcast } from "./dialogs/add_category_podcast"

export const PodcastCategoryPage = () => {
    const [page, setPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [lastPage, setLastPage] = useState(1)
    const [itemsCount, setItemsCount] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [modelOpen, setModelOpen] = useState(false)

    const [deleteModelOpen, setDeleteModelOpen] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [updateDocument, setUpdateDocument] = useState()

    const [title, setTitle] = useState('')

    const [lastRowItem, setLastRowItem] = useState();
    const [sortable, setSortable] = useState({
        name: 'title',
        type: 'asc'
    })



    const deleteCategory= async () => {
        try {
            console.log('deleteAd')
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await useDelete('podcast_category/'+deleteId);
            setDeleteModelOpen(false)
            setDeleteId(undefined)
            setRefresh(!refresh)
        } catch (e) {
            
            console.error("Error adding document: ", e);
        }
    }


    var sn = 0;


    const { loading, error, data } = useFetch('podcast_category',[refresh])

    if (loading) {
        return <Loader />
    }


    if (error) {
        console.log('errro ', error)
        return <h1>ERROR : </h1>
    }


    return (
        <>
            <AddCategoryPodcast
                updateCategory={updateDocument}
                modelOpen={modelOpen}
                onReload = {()=>{
                    setUpdateDocument(undefined)
                    setModelOpen(false)
                    setRefresh(!refresh)
                }}
                setModelOpen={() => {
                    setUpdateDocument(undefined)
                    setModelOpen(false)
                }} />

            <Modal
                isOpen={deleteModelOpen}
                toggle={() => {
                    setDeleteModelOpen(false);
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >

                <ModalBody className="text-center p-5">

                    <div className="mt-4">
                        <h4 className="mb-3">Delete ? </h4>
                        <p className="text-muted mb-4">Do you realy want to delete? </p>
                        <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={() => setDeleteModelOpen(false)}><i className="ri-close-line me-1 align-middle"></i> Close</Link>
                            <Link to="#" className="btn btn-danger" onClick={() => {deleteCategory() }}>Delete </Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>

                                    <div>
                                        <Button color="success" onClick={() => {
                                            setModelOpen(true)
                                        }}> Add Cateogry</Button>
                                    </div>
                                </CardHeader>

                                <CardBody className={"text-center"}>

                                    <div className="table-responsive">
                                        <Table className="table-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Title</th>
                                                    <th>Img</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map(row => {
                                                        sn++
                                                        return (<tr className="align-left" key={row['title']}>
                                                            <td>{"#" + sn}</td>
                                                            <td>{row['category']}</td>
                                                            <td><img width={30} height={30} src={row['img']} alt="" className="avatar rounded" /></td>
                                                            <td>
                                                                <Button color="success" size="sm" onClick={() => {
                                                                    setUpdateDocument(row)
                                                                    setModelOpen(true)
                                                                }}>Edit</Button>
                                                                <span style={{marginRight:"10px"}}></span>
                                                                <Button color="danger" size="sm" onClick={() => {
                                                                    setDeleteId(row['_id'])
                                                                    setDeleteModelOpen(true)
                                                                }}>Remove</Button></td>
                                                        </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

